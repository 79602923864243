/* @font-face {
    font-family: SourceHanSansCN-Bold;
    src: url(./resource/思源黑体/SourceHanSansCN-Bold.otf);
}
@font-face {
    font-family: SourceHanSansCN-ExtraLight;
    src: url(./resource/思源黑体/SourceHanSansCN-ExtraLight.otf);
}
@font-face {
    font-family: SourceHanSansCN-Heavy;
    src: url(./resource/思源黑体/SourceHanSansCN-Heavy.otf);
}
@font-face {
    font-family: SourceHanSansCN-Light;
    src: url(./resource/思源黑体/SourceHanSansCN-Light.otf);
}
@font-face {
    font-family: SourceHanSansCN-Medium;
    src: url(./resource/思源黑体/SourceHanSansCN-Medium.otf);
}
@font-face {
    font-family: SourceHanSansCN-Normal;
    src: url(./resource/思源黑体/SourceHanSansCN-Normal.otf);
}
@font-face {
    font-family: SourceHanSansCN-Regular;
    src: url(./resource/思源黑体/SourceHanSansCN-Regular.otf);
} */
:root,
#root,
#root > .App,
body {
    margin: 0;
    /* font-family: MicrosoftYaHei, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif; */
    font-family: MicrosoftYaHei, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif; 
    /* -webkit-font-smoothing: antialiased; */
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    /* -moz-osx-font-smoothing: grayscale; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
